import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/exo/exocontent.js";
import Prprecovery from "../sections/exo/exoglance.js";
import Faq from "../sections/exo/exofaq.js";
import Brand from "../sections/homepage/Brands.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/reviews/prpreviews.js";
import Pop from "../sections/landing/popular";
import Content2 from "../sections/exo/exocontent2.js";
import Content3 from "../sections/exo/exocontent3.js";
import Content4 from "../sections/exo/exopackage.js";
import Content5 from "../sections/exo/exocontent4.js"
import Saveface from "../sections/landing/saveface";
import Clinic from '../sections/nose/nosefind.js'; 
import MyImage from "../assets/image/jpeg/exosomes-hair-loss-treatment.jpg";
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import Award from "../sections/index/award.js";
import PopupForm from "../components/PopupForm/PopupForm"

const titleText = "EXOSOMES FOR HAIR LOSS";
  const textContent = "Unlock the Secrets to Fuller, Healthier Hair with Exosome";

const ExoLossPRPPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
            <Head title="Exosomes Hair Treatment London" image="/images/dermamina-home.jpg"  description="Regrow and strengthen hair with Exosomes hair treatment. Harness your body's natural healing for fuller, healthier hair. Discover the benefits now!" keywords="Exosomes hair treatment, Exosomes Hair loss therapy, Esosomes for hair, Hair regrowth treatment, Exosomes scalp treatment, Non-surgical hair restoration, Thinning hair solution, Exosomes injections for hair, Hair rejuvenation, Natural hair growth"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'PRP Hair Loss',
item:'https://www.dermamina.com/prp-for-hair-loss',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>

   
      <TwoColumnLayout title={titleText} text={textContent} imageData={null} image={MyImage} />
      <Prprecovery />
      <PopupForm
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
<Content
  setIsModalOpen={setIsModalOpen}
/>
<Content2 />
<Content4 />
<Content3 />
<Content5
  setIsModalOpen={setIsModalOpen}
/>
<Testimonial />
<Award />
<Faq
  setIsModalOpen={setIsModalOpen}
/>
<Pop />    
<Clinic /> 
<Brand />
<Saveface />

      </PageWrapper>
     
    </>
  );
};
export default ExoLossPRPPage;
